<template>
  <Layout>
    <template v-if="this.$route.name == 'add-page'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="page" @submit.prevent="
              $route.name == 'edit-page' ? updateData($route.params.id) : ''
            ">
              <div class="row">
                <b-form-group id="input-group-1" class="col-12">
                  <label for="input-1">Title <span style="color: red">*</span></label>
                  <b-form-input id="input-1" v-model="form.title" placeholder="Enter Title" :class="{
                    'is-invalid': submitted && $v.form.title.$error,
                  }"></b-form-input>
                  <div v-if="submitted && !$v.form.title.required" class="invalid-feedback">
                    Title is required.
                  </div>
                </b-form-group>
                <b-form-group id="input-group-2" class="col-12">
                  <label for="input-1">Description <span style="color: red">*</span></label>
                  <ckeditor v-model="form.description" :class="{
                    'is-invalid': submitted && $v.form.description.$error,
                  }"></ckeditor>
                  <div v-if="submitted && !$v.form.description.required" class="invalid-feedback">
                    Description is required.
                  </div>
                </b-form-group>
                <b-form-group id="input-group-6" class="col-12" label="Image [Upload Max File Size : 2MB]"
                  label-for="input-6">
                  <b-form-file id="input-6" accept="image/*" placeholder="Choose a file or drop it here..."
                    @change="readFile($event, 'image_name')" ref="image_name"></b-form-file>
                  <template v-if="$route.name == 'edit-page' && edit.image_name_url">
                    <img :src="edit.image_name_url" width="128px" height="128px"
                      style="object-fit: contain; margin-top: 5px" />
                  </template>
                  <template v-if="image_name_url">
                    <img :src="image_name_url" width="128px" height="128px" ref="image_name_url"
                      style="object-fit: contain; margin-top: 5px" />
                  </template>
                </b-form-group>
                <b-form-group id="input-group-3" label="Meta Title" label-for="input-3" class="col-6 position-relative">
                  <b-form-input id="input-3" v-model="form.meta_title" placeholder="Enter Meta Title" :maxlength="190">
                  </b-form-input>
                  <div class="text-right">
                    <p v-if="form.meta_title" class="badge position-absolute" style="top: 4px; right: 13px" :class="{
                      'badge-success': form.meta_title.length !== 190,
                      'badge-danger': form.meta_title.length === 190
                    }">
                      You typed
                      {{ form.meta_title.length }} out of 190 chars.
                    </p>
                  </div>
                </b-form-group>
                <b-form-group id="input-group-5" label="Meta Keywords" label-for="input-5"
                  class="col-6 position-relative">
                  <b-form-input id="input-5" :maxlength="250" v-model="form.meta_keywords"
                    placeholder="Enter Meta Keywords">
                  </b-form-input>
                  <div class="text-right">
                    <p v-if="form.meta_keywords" class="badge position-absolute" style="top: 4px; right: 13px" :class="{
                      'badge-success': form.meta_keywords.length !== 250,
                      'badge-danger': form.meta_keywords.length === 250
                    }">
                      You typed
                      {{ form.meta_keywords.length }} out of 250 chars.
                    </p>
                  </div>
                </b-form-group>
                <b-form-group id="input-group-4" label="Meta Description" label-for="input-4"
                  class="position-relative col-12">
                  <b-form-textarea id="input-4" :maxlength="400" v-model="form.meta_description"
                    placeholder="Enter Meta Description" rows="3"></b-form-textarea>
                  <div class="text-right">
                    <p v-if="form.meta_description" class="badge position-absolute" style="top: 4px; right: 13px"
                      :class="{
                        'badge-success': form.meta_description.length !== 400,
                        'badge-danger': form.meta_description.length === 400
                      }">
                      You typed
                      {{ form.meta_description.length }} out of 400 chars.
                    </p>
                  </div>
                </b-form-group>
              </div>
              <b-button type="submit" variant="primary" class="mr-2" v-if="$route.name == 'edit-page'"
                @click.prevent="updateData($route.params.id)">Update Data</b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import pageMixin from "../../../mixins/ModuleJs/page";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      submitted: false,
      title1: "Add Page",
      title2: "Edit Page",
      items: [
        {
          text: "Back",
          href: "/page",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  mixins: [MixinRequest, pageMixin],
  components: {
    Layout,
    PageHeader,
  },
  validations: {
    form: {
      title: { required },
      description: { required },
    },
  },
};
</script>
